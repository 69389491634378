import { VuexModule, Module, Action, Mutation, getModule } from 'vue-pandora-decorators';
import store from '@/store';

export interface IAPPState {
  // 系统配置
  fullTitle: string,
  shortTitle: string,
  subTitle: string,
  copyright: string,
  UA: string,
  // 菜单信息
  sider_collapsed: boolean,
  navbar_is_close: boolean,
  navbar_active: string,
  navbar_user_disabled: boolean,
  sidebar_is_close: boolean,
  // 企微信息

}

@Module({ dynamic: true, store, name: 'app' })
class APP extends VuexModule implements IAPPState {

  public fullTitle = '人力资源'
  public shortTitle = '人力资源'
  public subTitle = '管理系统'
  public copyright = '2014-2021 沈阳米拓科技有限公司'
  public UA = navigator.userAgent

  // 菜单信息
  sider_collapsed = false;
  navbar_is_close = false;
  navbar_active = '';
  navbar_user_disabled = false;
  sidebar_is_close = false;

  // 配置信息
  pageMinHeight = 0;
  loading = false

  @Mutation
  public SET_NAVBAR_CLOSED (toggle: boolean) { this.navbar_is_close = toggle }

  @Mutation
  public SET_NAVBAR_USER_DISABLED (status: boolean) { this.navbar_user_disabled = status }

  @Mutation
  public SET_NAVBAR_ACTIVE (path: string) { this.navbar_active = path }

  @Mutation
  public SET_SIDER_COLLAPSED (toggle: boolean) { this.sider_collapsed = toggle }

  @Mutation
  public SET_SIDEBAR_CLOSED (toggle: boolean) { this.sidebar_is_close = toggle }

  @Mutation
  public SET_LOADING (status: boolean) { this.loading = status }

  @Mutation
  public SET_PAGE_MIN_HEIGHT (height: number) { this.pageMinHeight += height }

}

// getModule 得到一个类型安全的store，module必须提供name属性
export const APPModule = getModule(APP)
