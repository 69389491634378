
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'

// 权限控制
import '@/peimission'

// 企业微信 Element 报错处理
if (navigator.userAgent.toLowerCase().indexOf("wechat") > -1) {
  Object.getOwnPropertyDescriptors = function (obj: any) {
    const result: any = {};
    for (let key of Reflect.ownKeys(obj)) {
      result[key] = Object.getOwnPropertyDescriptor(obj, key);
    }
    return result;
  };
}



import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'vxe-table-plugin-element/dist/style.css'
import VXETablePluginElement from 'vxe-table-plugin-element'
VXETable.use(VXETablePluginElement)


function useVxeTable (app: any) {
  app.use(VXETable)

  // 给 vue 实例挂载内部对象，例如：
  // app.config.globalProperties.$XModal = VXETable.modal
  // app.config.globalProperties.$XPrint = VXETable.print
  // app.config.globalProperties.$XSaveFile = VXETable.saveFile
  // app.config.globalProperties.$XReadFile = VXETable.readFile
}

const app = createApp(App)

app.use(ElementPlus, { locale })
  .use(useVxeTable)
  .use(store)
  .use(router)
  .mount('#app')


import { getTableScroll } from '@/plugin/tools'
app.provide('$getScroll', getTableScroll);
