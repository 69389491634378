import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import application from './modules/application'
import roster from './modules/roster'
// import development from './modules/development'

export const constantRoutes: RouteRecordRaw[] = application
export const asyncRoutes: RouteRecordRaw[] = [
  roster,
  // development
]

formatRoutes(asyncRoutes)

// asyncRoutes.push({
//   path: '*',
//   redirect: '/404',
//   meta: { title: '页面未找到', hidden: true }
// })


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

export default router


function formatRoutes (routesList: RouteRecordRaw[]) {
  for (const route of routesList) {
    if (!route.path || route.path === '*') return

    /** 路由名称 */
    route.name = getRouteName(route.path)

    /** 路由角色 */
    if (route.meta?.roles && route.meta.roles.length === 0) {
      route.meta.roles = [route.name]
    }

    // 递归
    if (route.children && route.children.length > 0) {
      formatRoutes(route.children)
    }

  }
}

function getRouteName (str: string) {
  const index = str.indexOf('/')
  return str.substring(index + 1, str.length).replace(/\//g, '_')
}