import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/Roster',
  component: Layout,
  redirect: '/Home',
  name: 'Roster',
  meta: { title: '员工', icon: 'lock' },
  children: [
    {
      path: '/Roster/Employee',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/Roster/Employee/Index.vue'),
      meta: { title: '员工管理', icon: 'el-icon-menu', roles: [], sider_is_close: false }
    },
    {
      path: '/Roster/Employee/Information',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/Roster/Employee/Information.vue'),
      meta: { title: '员工档案', icon: 'el-icon-menu', roles: [], hidden: true }
    },
    {
      path: '/Roster/Employee/Entry',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/Roster/Employee/Entry.vue'),
      meta: { title: '手机入职', icon: 'el-icon-menu', roles: [], hidden: true }
    }
  ]
}