<template>
  <el-header style="padding:0;">
    <el-container>
      <div style="min-width:199px">
        <div class="logo-container">
          <img src="@/assets/logo.png" class="logo-img" />
          <div class="logo-title">{{ shortTitle }}</div>
        </div>
      </div>
      <div class="dev2" style="flex:1;">
        <!-- <div >1</div> -->
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="rgb(64, 158, 255)"
          style="display:flex;justify-content:flex-end;position:static;"
        >
          <el-menu-item
            :index="item.path"
            v-for="(item) in routes"
            :key="item.path"
          >{{ item.meta.title }}</el-menu-item>

          <el-menu-item index="4">
            <el-dropdown @command="handleAvatar">
              <el-avatar
                :size="48"
                :src="avatar"
                shape="square"
                style="background-color:rgb(84, 92, 100)"
              ></el-avatar>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </div>
    </el-container>
  </el-header>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { APPModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { PermissionModule } from '@/store/modules/permission'
import linq from 'linq'
export default defineComponent({
  components: {

  },
  setup () {

    const route = useRoute();
    const router = useRouter();

    const routes = computed(() => {
      let result = PermissionModule.routes.filter(r => !r.meta?.hidden && r.children && r.children.length > 0)
      return result;
    })



    const avatar = computed(() => UserModule.avatar)

    const activeIndex = computed(() => {
      const { matched } = route
      let path = matched[0].path
      if (!path) { path = matched[1].path }
      APPModule.SET_NAVBAR_ACTIVE(path)
      return path
    })

    // const activeIndex = ref('1');
    const handleSelect = (key: any, keyPath: any) => {
      APPModule.SET_NAVBAR_ACTIVE(key)

      let result = PermissionModule.routes.filter(r => !r.meta?.hidden && r.children && r.children.length > 0)
      const menu = linq.from(result).where((f: any) => f.path === key).firstOrDefault()

      if (menu?.children && menu.children.length > 0) {
        if (menu?.children[0].path === route.path) { return }
        router.push({ path: menu?.children[0].path })
      }
    };

    const handleAvatar = (command: string) => {
      switch (command) {
        case 'logout':
          UserModule.LogOut()
          router.push({ path: '/Login' })
          break;
      }
    }

    return {
      routes: routes,
      shortTitle: APPModule.shortTitle,
      activeIndex,
      handleSelect,
      handleAvatar,
      avatar
    }

  }
});
</script>

<style lang="scss" scoped>
.logo-container {
  margin-left: 10px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  .logo-img {
    line-height: 60px;
    height: 60px;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .logo-title {
    line-height: 60px;
    height: 60px;
    display: inline-block;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
    font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif, "SimSun";
    vertical-align: middle;
  }
}
</style>

<style>
.el-header {
  background-color: rgb(84, 92, 100);
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>