<template>
    <div class="common-layout">
        <el-container style="height:100vh;" direction="vertical">
            <Navbar />
            <el-container>
                <Sidebar />
                <el-container>
                    <el-main style="height:calc(100vh - 101px);">
                        <router-view />
                    </el-main>
                </el-container>
            </el-container>
            <Footer />
        </el-container>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { APPModule } from '@/store/modules/app';
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'
export default defineComponent({
    components: {
        Navbar, Sidebar, Footer
    },
    setup () {

        return {
            copyright: APPModule.copyright,
        }
    }
});
</script>

